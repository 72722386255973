import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import {
    Button,
    Grid,
    Header,
    Segment,
} from 'semantic-ui-react'
import '../../styles/custom.css';


export class PublicAuction extends React.Component {
    render() {
        return (
            <Segment id={"PublicAuctionSegment"} basic style={{
                padding: '4em 0em 4em 0em',
            }}
                     vertical>
                <Grid container stackable centered verticalAlign='middle' id={"PublicAuctionGrid"}>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOnce={true}>
                        <Grid.Row centered verticalAlign='middle' id={"ContainerRow"}
                        >
                            <Grid.Column centered verticalAlign='middle' id={"ContainerCol"}>
                                <Header as='h3' id="Header" style={{
                                    fontSize: '3.5em',
                                    fontFamily: 'Montserrat',
                                    padding: '1em 0em 1em 0em',
                                    color: '#004A8D',
                                }}>Публичен търг</Header>
                                <Grid.Row centered verticalAlign='middle'
                                          style={{padding: '2em 0em 5em 0em',}}>
                                    <Grid.Column centered verticalAlign='middle'>
                                        <Grid.Row centered verticalAlign='middle'
                                                  style={{padding: '2em 0em 2em 0em',}}>
                                            <AuctionDocumentsButton/>
                                        </Grid.Row>
                                        <Description/>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid.Column>
                        </Grid.Row>
                    </ScrollAnimation>
                </Grid>
            </Segment>
        )
    }
}

const Description = () => {
    return (
        <p style={{fontSize: '1.0em', color: '#004A8D'}}>
            В изпълнение на проект BG-RRP-3.008-0688-C01 „Кяшиф“ ЕООД обявява процедура за избор на изпълнител с
            публична покана с предмет „Доставка и въвеждане в експлоатация на металообработващо оборудване по 2
            обособени позиции:
            Обособена позиция 1 - Струг с ЦПУ с "Y"-ос;
            Oбособена позиция 2 - Вертикален Обработващ Център с ЦПУ.“
            Краен срок за подаване на оферти: до 23:59ч. на 31.03.2025г.
        </p>
    )
}

const AuctionDocumentsButton = () => {
    // Define the gtag_report_conversion function inside the component
    const gtag_report_conversion = (url) => {
        var callback = function () {
            if (typeof (url) !== 'undefined') {
                window.location = url;
            }
        };
        // Use window.gtag to ensure it's recognized
        window.gtag('event', 'conversion', {
            'send_to': 'AW-11258023337/_YuDCPjT3fYZEKmbn_gp',
            'value': 1.0,
            'currency': 'BGN',
            'event_callback': callback
        });
        return false;
    };

    return (
        <Button
            className="public-auction-button"
            id="public-auction-button"
            target="_blank"
            onClick={() => gtag_report_conversion('https://storage.googleapis.com/emkpublic/kyashif/%D0%A2%D1%8A%D1%80%D0%B3%20%D0%9A%D1%8F%D1%88%D0%B8%D1%84.zip')}
        >
            Документи за търг
        </Button>
    );
};