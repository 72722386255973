import React, {Component} from 'react';
import HomepageLayout from './components/Main'
import "animate.css/animate.min.css";
import './styles/body.css'

class App extends Component {
    render() {
        return (
            <div className="App">
                <HomepageLayout/>
            </div>
        );
    }
}

export default App;
