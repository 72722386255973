import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import {Divider, Grid, Header, List, Segment} from 'semantic-ui-react'
import {Certificates} from './Certificates.jsx'
import {ImageGallery} from './ImageGallery'

export class Capabilities extends React.Component {
    render() {
        return (
            <Segment id={"CapabilitiesSegment"} basic style={{padding: '8em 0em 8em 0em'}} vertical>
                <Grid container stackable verticalAlign='middle' id={"CapabilitiesGrid"}>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOnce={true}>
                        <Grid.Row centered verticalAlign='middle' id={"ContainerRow"}>
                            <Grid.Column verticalAlign='middle' id={"ContainerCol"}>
                                <Header as='h3' id="Header" style={{
                                    fontSize: '3.5em',
                                    fontFamily: 'Montserrat',
                                    padding: '2em 0em 1em 0em'
                                }}>Capabilities</Header>
                                <Grid.Row id={"ImageGalleryRow"} centered verticalAlign='middle' columns={1} style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    // flexGrow: 1
                                    marginLeft: '1em',
                                }}>
                                    <ImageGallery/>

                                </Grid.Row>
                                <Grid.Row id={"CapabilitiesListRow"} style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'nowrap',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    padding: '6em 2em 12em 2em'
                                }}><CapabilitiesList/></Grid.Row>
                                <Grid.Row id={"CertificatesRow"} centered verticalAlign='middle'>
                                    <Divider/>
                                    <Certificates/>
                                    <Divider/>
                                </Grid.Row>

                            </Grid.Column>
                        </Grid.Row>
                    </ScrollAnimation>
                </Grid>
            </Segment>
        )
    }
}

//   export const Capabilities = () => {
//         return (
//             <Segment basic style={{ padding: '8em 0em' }} vertical>
//                 <Grid container stackable verticalAlign='middle'>
//                     <ScrollAnimation 
//                         animateIn="fadeInUp"
//                         animateOnce={true}>
//                             <Grid.Row centered verticalAlign='middle'>
//                             <Grid.Column verticalAlign='middle'>
//                         <Header as='h3' style={{ fontSize: '3.5em', fontFamily: 'Montserrat'}}>Capabilities</Header>
//                         <Grid.Row centered verticalAlign='middle' columns={1} style={{display:'flex', flexDirection:'row', flexWrap:'nowrap', justifyContent:'center', alignItems:'center', alignContent:'center', marginBottom:'50px'}}>
//                         <ImageGallery/>
//                         </Grid.Row>
//                         <Grid.Row><CapabilitiesList/></Grid.Row>
//                         <Grid.Row centered verticalAlign='middle'>
//                             <Divider />
//                             <Certificates />
//                             <Divider />
//                         </Grid.Row>

//                         </Grid.Column>
//                         </Grid.Row>
//                     </ScrollAnimation>
//                 </Grid>
//             </Segment>
//         )
//     }

const CapabilitiesList = () => {
    return (
        <div>
            <List style={{fontSize: '1.5em'}}>
                <List.Item>- Own design and analysis: 3D modelling of products and factory with Autodesk.</List.Item>
                <br/>
                <List.Item>- Flexible production: CNC machining, CAM, skiving and rollerburnishing, ultrasonic washing,
                    robot friction welding, etc.</List.Item> <br/>
                <List.Item>- High quality and continuous improvements: own laboratory, ultrasonic, salt spray testing
                    and many more.</List.Item> <br/>
                <List.Item>- Efficient processes and communication: ERP, cloud-based IT infrastructure based on
                    Microsoft technologies.</List.Item> <br/>
            </List>
        </div>
    )
}