// const imagePath = '/img/capabilities/';
//   const imagePath = 'https://storage.googleapis.com/emkpublic/kyashif/Images/';
const imagePath = 'https://storage.googleapis.com/emkpublic/kyashif/Images/Capabilities/';

// Computer only will be used
const thumbnailWidthComp = 40
const thumbnailHeightComp = 35
export const photosArrayComputer = [
    // 1st row
    {
        src: `${imagePath}${'1Mechanical1.jpg'}`,
        thumbnail: `${imagePath}${'1Mechanical1Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidthComp,
        thumbnailHeight: thumbnailHeightComp,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'2MachineEquip2.jpg'}`,
        thumbnail: `${imagePath}${'2MachineEquip2Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidthComp,
        thumbnailHeight: thumbnailHeightComp,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'2MachineEquip4.jpg'}`,
        thumbnail: `${imagePath}${'2MachineEquip4Thumb.jpg'}`,
        thumbnailWidth: 40,
        thumbnailHeight: 30,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'2MachineEquip5.jpg'}`,
        thumbnail: `${imagePath}${'2MachineEquip5Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidthComp,
        thumbnailHeight: thumbnailHeightComp,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'3Tacchi1.jpg'}`,
        thumbnail: `${imagePath}${'3Tacchi1Thumb.jpg'}`,
        thumbnailWidth: 40,
        thumbnailHeight: 40,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'3Tacchi3.jpg'}`,
        thumbnail: `${imagePath}${'3Tacchi3Thumb.jpg'}`,
        thumbnailWidth: 35,
        thumbnailHeight: 40,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'4CLOOS1.jpg'}`,
        thumbnail: `${imagePath}${'4CLOOS1Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidthComp,
        thumbnailHeight: thumbnailHeightComp,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'4CLOOS3.jpg'}`,
        thumbnail: `${imagePath}${'4CLOOS3Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidthComp,
        thumbnailHeight: thumbnailHeightComp,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'5Thompson1.png'}`,
        thumbnail: `${imagePath}${'5Thompson1Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidthComp,
        thumbnailHeight: thumbnailHeightComp,
        alt: 'Kyashif production'
    },
    //
    {
        src: `${imagePath}${'6Assembly1.jpg'}`,
        thumbnail: `${imagePath}${'6Assembly1Thumb.jpg'}`,
        thumbnailWidth: 35,
        thumbnailHeight: thumbnailHeightComp,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'6Assembly3.jpg'}`,
        thumbnail: `${imagePath}${'6Assembly3Thumb.jpg'}`,
        thumbnailWidth: 35,
        thumbnailHeight: thumbnailHeightComp,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'6Assembly4.jpg'}`,
        thumbnail: `${imagePath}${'6Assembly4Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidthComp,
        thumbnailHeight: thumbnailHeightComp,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'6Assembly6.jpg'}`,
        thumbnail: `${imagePath}${'6Assembly6Thumb.jpg'}`,
        thumbnailWidth: 34,
        thumbnailHeight: thumbnailHeightComp,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'6Assembly7.jpg'}`,
        thumbnail: `${imagePath}${'6Assembly7Thumb.jpg'}`,
        thumbnailWidth: 33.5,
        thumbnailHeight: thumbnailHeightComp,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'7PaintingShop1.jpg'}`,
        thumbnail: `${imagePath}${'7PaintingShop1Thumb.jpg'}`,
        thumbnailWidth: 35,
        thumbnailHeight: thumbnailHeightComp,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'7PaintingShop4.jpg'}`,
        thumbnail: `${imagePath}${'7PaintingShop4Thumb.jpg'}`,
        thumbnailWidth: 35,
        thumbnailHeight: thumbnailHeightComp,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'7PaintingShop12.jpg'}`,
        thumbnail: `${imagePath}${'7PaintingShop12Thumb.jpg'}`,
        thumbnailWidth: 30,
        thumbnailHeight: thumbnailHeightComp,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'8Quality11.jpg'}`,
        thumbnail: `${imagePath}${'8Quality11thumb.jpg'}`,
        thumbnailWidth: 30,
        thumbnailHeight: thumbnailHeightComp,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'8Quality3.jpg'}`,
        thumbnail: `${imagePath}${'8Quality3Thumb.jpg'}`,
        thumbnailWidth: 30,
        thumbnailHeight: 45,
        alt: 'Kyashif production'
    },
]

// Tablet only will be used
const thumbnailWidthTablet = 100
const thumbnailHeightTablet = 65
export const photosArrayTablet = [
    // 1st row
    {
        src: `${imagePath}${'1Mechanical1.jpg'}`,
        thumbnail: `${imagePath}${'1Mechanical1Thumb.jpg'}`,
        thumbnailWidth: 120,
        thumbnailHeight: thumbnailHeightTablet,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'2MachineEquip2.jpg'}`,
        thumbnail: `${imagePath}${'2MachineEquip2Thumb.jpg'}`,
        thumbnailWidth: 100,
        thumbnailHeight: thumbnailHeightTablet,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'2MachineEquip4.jpg'}`,
        thumbnail: `${imagePath}${'2MachineEquip4Thumb.jpg'}`,
        thumbnailWidth: 120,
        thumbnailHeight: thumbnailHeightTablet,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'2MachineEquip5.jpg'}`,
        thumbnail: `${imagePath}${'2MachineEquip5Thumb.jpg'}`,
        thumbnailWidth: 122,
        thumbnailHeight: thumbnailHeightTablet,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'3Tacchi1.jpg'}`,
        thumbnail: `${imagePath}${'3Tacchi1Thumb.jpg'}`,
        thumbnailWidth: 127,
        thumbnailHeight: thumbnailHeightTablet,
        alt: 'Kyashif production'
    },
    // 2nd row
    {
        src: `${imagePath}${'3Tacchi3.jpg'}`,
        thumbnail: `${imagePath}${'3Tacchi3Thumb.jpg'}`,
        thumbnailWidth: 90,
        thumbnailHeight: thumbnailHeightTablet,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'4CLOOS1.jpg'}`,
        thumbnail: `${imagePath}${'4CLOOS1Thumb.jpg'}`,
        thumbnailWidth: 80,
        thumbnailHeight: thumbnailHeightTablet,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'4CLOOS3.jpg'}`,
        thumbnail: `${imagePath}${'4CLOOS3Thumb.jpg'}`,
        thumbnailWidth: 90,
        thumbnailHeight: thumbnailHeightTablet,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'5Thompson1.png'}`,
        thumbnail: `${imagePath}${'5Thompson1Thumb.jpg'}`,
        thumbnailWidth: 90,
        thumbnailHeight: thumbnailHeightTablet,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'6Assembly7.jpg'}`,
        thumbnail: `${imagePath}${'6Assembly7Thumb.jpg'}`,
        thumbnailWidth: 35,
        thumbnailHeight: thumbnailHeightTablet,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'6Assembly1.jpg'}`,
        thumbnail: `${imagePath}${'6Assembly1Thumb.jpg'}`,
        thumbnailWidth: 90,
        thumbnailHeight: thumbnailHeightTablet,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'6Assembly3.jpg'}`,
        thumbnail: `${imagePath}${'6Assembly3Thumb.jpg'}`,
        thumbnailWidth: 89,
        thumbnailHeight: thumbnailHeightTablet,
        alt: 'Kyashif production'
    },
    // 3rd row
    {
        src: `${imagePath}${'6Assembly4.jpg'}`,
        thumbnail: `${imagePath}${'6Assembly4Thumb.jpg'}`,
        thumbnailWidth: 100,
        thumbnailHeight: thumbnailHeightTablet,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'6Assembly6.jpg'}`,
        thumbnail: `${imagePath}${'6Assembly6Thumb.jpg'}`,
        thumbnailWidth: 70,
        thumbnailHeight: thumbnailHeightTablet,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'7PaintingShop1.jpg'}`,
        thumbnail: `${imagePath}${'7PaintingShop1Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidthTablet,
        thumbnailHeight: thumbnailHeightTablet,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'7PaintingShop4.jpg'}`,
        thumbnail: `${imagePath}${'7PaintingShop4Thumb.jpg'}`,
        thumbnailWidth: 65,
        thumbnailHeight: thumbnailHeightTablet,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'7PaintingShop12.jpg'}`,
        thumbnail: `${imagePath}${'7PaintingShop12Thumb.jpg'}`,
        thumbnailWidth: 60,
        thumbnailHeight: thumbnailHeightTablet,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'8Quality11.jpg'}`,
        thumbnail: `${imagePath}${'8Quality11thumb.jpg'}`,
        thumbnailWidth: 65,
        thumbnailHeight: thumbnailHeightTablet,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'8Quality3.jpg'}`,
        thumbnail: `${imagePath}${'8Quality3Thumb.jpg'}`,
        thumbnailWidth: 61,
        thumbnailHeight: thumbnailHeightTablet,
        alt: 'Kyashif production'
    },
]

// Mobile only will be used
const thumbnailHeightMobile = 60
export const photosArrayMobile = [
    // 1st row
    {
        src: `${imagePath}1Mechanical1.jpg`,
        thumbnail: `${imagePath}1Mechanical1Thumb.jpg`,
        thumbnailWidth: 75,
        thumbnailHeight: thumbnailHeightMobile,
        alt: 'Kyashif production',
    },
    {
        src: `${imagePath}2MachineEquip2.jpg`,
        thumbnail: `${imagePath}2MachineEquip2Thumb.jpg`,
        thumbnailWidth: 80,
        thumbnailHeight: thumbnailHeightMobile,
        alt: 'Kyashif production',
    },
    {
        src: `${imagePath}2MachineEquip4.jpg`,
        thumbnail: `${imagePath}2MachineEquip4Thumb.jpg`,
        thumbnailWidth: 80,
        thumbnailHeight: thumbnailHeightMobile,
        alt: 'Kyashif production',
    },
    {
        src: `${imagePath}2MachineEquip5.jpg`,
        thumbnail: `${imagePath}2MachineEquip5Thumb.jpg`,
        thumbnailWidth: 75,
        thumbnailHeight: thumbnailHeightMobile,
        alt: 'Kyashif production',
    },

    {
        src: `${imagePath}3Tacchi1.jpg`,
        thumbnail: `${imagePath}3Tacchi1Thumb.jpg`,
        thumbnailWidth: 61,
        thumbnailHeight: thumbnailHeightMobile,
        alt: 'Kyashif production',
    },
    {
        src: `${imagePath}3Tacchi3.jpg`,
        thumbnail: `${imagePath}3Tacchi3Thumb.jpg`,
        thumbnailWidth: 63,
        thumbnailHeight: thumbnailHeightMobile,
        alt: 'Kyashif production',
    },
    // 2nd row
    {
        src: `${imagePath}4CLOOS1.jpg`,
        thumbnail: `${imagePath}4CLOOS1Thumb.jpg`,
        thumbnailWidth: 65,
        thumbnailHeight: thumbnailHeightMobile,
        alt: 'Kyashif production',
    },
    {
        src: `${imagePath}4CLOOS3.jpg`,
        thumbnail: `${imagePath}4CLOOS3Thumb.jpg`,
        thumbnailWidth: 60,
        thumbnailHeight: thumbnailHeightMobile,
        alt: 'Kyashif production',
    },
    {
        src: `${imagePath}5Thompson1.png`,
        thumbnail: `${imagePath}5Thompson1Thumb.jpg`,
        thumbnailWidth: 63,
        thumbnailHeight: thumbnailHeightMobile,
        alt: 'Kyashif production',
    },
    {
        src: `${imagePath}6Assembly1.jpg`,
        thumbnail: `${imagePath}6Assembly1Thumb.jpg`,
        thumbnailWidth: 72,
        thumbnailHeight: thumbnailHeightMobile,
        alt: 'Kyashif production',
    },
    {
        src: `${imagePath}6Assembly3.jpg`,
        thumbnail: `${imagePath}6Assembly3Thumb.jpg`,
        thumbnailWidth: 67,
        thumbnailHeight: thumbnailHeightMobile,
        alt: 'Kyashif production',
    },
    {
        src: `${imagePath}6Assembly4.jpg`,
        thumbnail: `${imagePath}6Assembly4Thumb.jpg`,
        thumbnailWidth: 85,
        thumbnailHeight: thumbnailHeightMobile,
        alt: 'Kyashif production',
    },
    // 3nd row
    {
        src: `${imagePath}6Assembly6.jpg`,
        thumbnail: `${imagePath}6Assembly6Thumb.jpg`,
        thumbnailWidth: 65,
        thumbnailHeight: thumbnailHeightMobile,
        alt: 'Kyashif production',
    },
    {
        src: `${imagePath}6Assembly7.jpg`,
        thumbnail: `${imagePath}6Assembly7Thumb.jpg`,
        thumbnailWidth: 60,
        thumbnailHeight: thumbnailHeightMobile,
        alt: 'Kyashif production',
    },
    {
        src: `${imagePath}7PaintingShop1.jpg`,
        thumbnail: `${imagePath}7PaintingShop1Thumb.jpg`,
        thumbnailWidth: 40,
        thumbnailHeight: thumbnailHeightMobile,
        alt: 'Kyashif production',
    },
    {
        src: `${imagePath}7PaintingShop4.jpg`,
        thumbnail: `${imagePath}7PaintingShop4Thumb.jpg`,
        thumbnailWidth: 65,
        thumbnailHeight: thumbnailHeightMobile,
        alt: 'Kyashif production',
    },
    {
        src: `${imagePath}7PaintingShop12.jpg`,
        thumbnail: `${imagePath}7PaintingShop12Thumb.jpg`,
        thumbnailWidth: 65,
        thumbnailHeight: thumbnailHeightMobile,
        alt: 'Kyashif production',
    },
    {
        src: `${imagePath}8Quality11.jpg`,
        thumbnail: `${imagePath}8Quality11thumb.jpg`,
        thumbnailWidth: 60,
        thumbnailHeight: thumbnailHeightMobile,
        alt: 'Kyashif production',
    },
    {
        src: `${imagePath}8Quality3.jpg`,
        thumbnail: `${imagePath}8Quality3Thumb.jpg`,
        thumbnailWidth: 55,
        thumbnailHeight: thumbnailHeightMobile,
        alt: 'Kyashif production',
    },
];


// export const photosArrayMobile = [
//     {
//         src: `${imagePath}${'1Mechanical1.jpg'}`,
//         thumbnail: `${imagePath}${'1Mechanical1Thumb.jpg'}`,
//         thumbnailWidth: 150,
//         thumbnailHeight: 120,
//         alt: 'Kyashif production'
//     },
//     {
//         src: `${imagePath}${'2MachineEquip2.jpg'}`,
//         thumbnail: `${imagePath}${'2MachineEquip2Thumb.jpg'}`,
//         thumbnailWidth: 43,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production'
//     },
//     {
//         src: `${imagePath}${'2MachineEquip4.jpg'}`,
//         thumbnail: `${imagePath}${'2MachineEquip4Thumb.jpg'}`,
//         thumbnailWidth: 43,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production'
//     },
//     {
//         src: `${imagePath}${'2MachineEquip5.jpg'}`,
//         thumbnail: `${imagePath}${'2MachineEquip5Thumb.jpg'}`,
//         thumbnailWidth: 37,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production'
//     },
//     {
//         src: `${imagePath}${'3Tacchi1.jpg'}`,
//         thumbnail: `${imagePath}${'3Tacchi1Thumb.jpg'}`,
//         thumbnailWidth: 35,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production'
//     },
//     {
//         src: `${imagePath}${'3Tacchi3.jpg'}`,
//         thumbnail: `${imagePath}${'3Tacchi3Thumb.jpg'}`,
//         thumbnailWidth: thumbnailWidthMobile,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production'
//     },
//     {
//         src: `${imagePath}${'4CLOOS1.jpg'}`,
//         thumbnail: `${imagePath}${'4CLOOS1Thumb.jpg'}`,
//         thumbnailWidth: thumbnailWidthMobile,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production'
//     },
//     {
//         src: `${imagePath}${'4CLOOS3.jpg'}`,
//         thumbnail: `${imagePath}${'4CLOOS3Thumb.jpg'}`,
//         thumbnailWidth: thumbnailWidthMobile,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production'
//     },
//     {
//         src: `${imagePath}${'5Thompson1.png'}`,
//         thumbnail: `${imagePath}${'5Thompson1Thumb.jpg'}`,
//         thumbnailWidth: 45,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production'
//     },
//     {
//         src: `${imagePath}${'6Assembly1.jpg'}`,
//         thumbnail: `${imagePath}${'6Assembly1Thumb.jpg'}`,
//         thumbnailWidth: thumbnailWidthMobile,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production'
//     },
//     {
//         src: `${imagePath}${'6Assembly3.jpg'}`,
//         thumbnail: `${imagePath}${'6Assembly3Thumb.jpg'}`,
//         thumbnailWidth: 37,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production'
//     },
//     {
//         src: `${imagePath}${'6Assembly4.jpg'}`,
//         thumbnail: `${imagePath}${'6Assembly4Thumb.jpg'}`,
//         thumbnailWidth: 50,
//         // thumbnailWidth: 60,
//         thumbnailHeight: 35,
//         alt: 'Kyashif production'
//     },
//     {
//         src: `${imagePath}${'6Assembly6.jpg'}`,
//         thumbnail: `${imagePath}${'6Assembly6Thumb.jpg'}`,
//         thumbnailWidth: thumbnailWidthMobile,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production'
//     },
//     {
//         src: `${imagePath}${'6Assembly7.jpg'}`,
//         thumbnail: `${imagePath}${'6Assembly7Thumb.jpg'}`,
//         thumbnailWidth: 35,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production'
//     },
//     {
//         src: `${imagePath}${'7PaintingShop1.jpg'}`,
//         thumbnail: `${imagePath}${'7PaintingShop1Thumb.jpg'}`,
//         thumbnailWidth: 35,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production'
//     },
//     {
//         src: `${imagePath}${'7PaintingShop4.jpg'}`,
//         thumbnail: `${imagePath}${'7PaintingShop4Thumb.jpg'}`,
//         // thumbnailWidth: 50,
//         thumbnailWidth: 30,
//         thumbnailHeight: 35,
//         alt: 'Kyashif production'
//     },
//     {
//         src: `${imagePath}${'7PaintingShop12.jpg'}`,
//         thumbnail: `${imagePath}${'7PaintingShop12Thumb.jpg'}`,
//         // thumbnailWidth: 50,
//         thumbnailWidth: 25,
//         thumbnailHeight: 35,
//         alt: 'Kyashif production'
//     },
//     {
//         src: `${imagePath}${'8Quality11.jpg'}`,
//         thumbnail: `${imagePath}${'8Quality11thumb.jpg'}`,
//         thumbnailWidth: thumbnailWidthMobile,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production'
//     },
//     {
//         src: `${imagePath}${'8Quality3.jpg'}`,
//         thumbnail: `${imagePath}${'8Quality3Thumb.jpg'}`,
//         // thumbnailWidth: 35,
//         thumbnailWidth: 31,
//         thumbnailHeight: 40,
//         alt: 'Kyashif production'
//     },
// ]


// export const imagesNames = [
//     {
//         src:'1Mechanical1.jpg',
//         thumbnail: '1Mechanical1Thumb.jpg'
//     },
//     {
//         src:'2MachineEquip2.jpg',
//         thumbnail: '2MachineEquip2Thumb.jpg'
//     },
//     {
//         src:'2MachineEquip4.jpg',
//         thumbnail: '2MachineEquip4Thumb.jpg'
//     },
//     {
//         src:'2MachineEquip5.jpg',
//         thumbnail: '2MachineEquip5Thumb.jpg'
//     },
//     {
//         src:'3Tacchi1.jpg',
//         thumbnail: '3Tacchi1Thumb.jpg'
//     },
//     {
//         src:'3Tacchi3.jpg',
//         thumbnail: '3Tacchi3Thumb.jpg'
//     },
//     {
//         src:'4CLOOS2.jpg',
//         thumbnail: '4CLOOS2Thumb.jpg'
//     },
//     {
//         src:'4CLOOS3.jpg',
//         thumbnail: '4CLOOS3Thumb.jpg'
//     },
//     {
//         src:'5Thompson1.jpg',
//         thumbnail: '5Thompson1Thumb.jpg'
//     },
//     {
//         src:'6Assembly1.jpg',
//         thumbnail: '6Assembly1Thumb.jpg'
//     },
//     {
//         src:'6Assembly3.jpg',
//         thumbnail: '6Assembly3Thumb.jpg'
//     },
//     {
//         src:'6Assembly4.jpg',
//         thumbnail: '6Assembly4Thumb.jpg'
//     },
//     {
//         src:'6Assembly6Thumb.jpg',
//         thumbnail: '6Assembly6Thumb.jpg'
//     },
//     {
//         src:'6Assembly7.jpg',
//         thumbnail: '6Assembly7Thumb.jpg'
//     },
//     {
//         src:'7PaintingShop1.jpg',
//         thumbnail: '7PaintingShop1Thumb.jpg'
//     },
//     {
//         src:'7PaintingShop4.jpg',
//         thumbnail: '7PaintingShop4Thumb.jpg'
//     },
//     {
//         src:'7PaintingShop12.jpg',
//         thumbnail: '7PaintingShop12Thumb.jpg'
//     },
//     {
//         src:'8Quality11.jpg',
//         thumbnail: '8Quality11thumb.jpg'
//     },
// ]


// export const imagesNames = [
//     '1Collage11.svg',
//     '1Mechanical1th.JPG',
//     '2MachineEquip1.PNG',
//     '2MachineEquip3.JPG',
//     '2MachineEquip4th.jpg',
//     '2MachineEquip5th.jpg',
//     '2MachineEquip6.jpg',
//     '2MachineEquip7.jpg',
//     '2MachineEquip8.jpg',
//     '3Tacchi1.JPG',
//     '3Tacchi2.jpg',
//     '3Tacchi3th.jpg',
//     '3Tacchi4.jpg',
//     '4CLOOS1.png',
//     '4CLOOS2th.jpg',
//     '4CLOOS3th.jpg',
//     '4CLOOS4.jpg',
//     '5Thompson1th.png',
//     '5Thompson2th.PNG',
//     '5Thompson3th.JPG',
//     '5Thompson4.jpg',
//     '6Assembly1th.jpg',
//     '6Assembly2.JPG',
//     '6Assembly3.jpg',
//     '6Assembly4th.JPG',
//     '6Assembly5.jpg',
//     '6Assembly6.png',
//     '6Assembly7th.JPG',
//     '6Assembly8.JPG',
//     '6Assembly9.jpg',
//     '7PaintingShop1th.jpg',
//     '7PaintingShop2.jpg',
//     '7PaintingShop4.JPG',
//     '8Quality1th.jpg',
//     '8Quality2th.png',
//     '8Quality3.jpg',
//     '8Quality4.jpg',
//     '8Quality5.jpg',
//     '8Quality6.jpg',
//     '8Quality7.png',
//     '8Quality8.JPG',
//     '8Quality9.jpg',
//     '8Quality11th.jpg',
//     '8Quality12.JPG',

// ]

// exluded - '8Quality12.JPG', '8Quality13.PNG', '8Quality2.png',  '8Quality3.jpg',  '8Quality4.jpg',  '8Quality5.jpg',  '8Quality6.jpg',  '8Quality7.png', '8Quality8.JPG', '8Quality9.jpg',


// const thumbnailWidthMobile = 56
// const thumbnailHeightMobile = 55
// export const photosArrayMobile = [
//     {
//         src: `${imagePath}1Mechanical1.jpg`,
//         thumbnail: `${imagePath}1Mechanical1Thumb.jpg`,
//         thumbnailWidth: 75,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production',
//     },
//     {
//         src: `${imagePath}2MachineEquip2.jpg`,
//         thumbnail: `${imagePath}2MachineEquip2Thumb.jpg`,
//         thumbnailWidth: 80,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production',
//     },
//     {
//         src: `${imagePath}2MachineEquip4.jpg`,
//         thumbnail: `${imagePath}2MachineEquip4Thumb.jpg`,
//         thumbnailWidth: 80,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production',
//     },
//     {
//         src: `${imagePath}2MachineEquip5.jpg`,
//         thumbnail: `${imagePath}2MachineEquip5Thumb.jpg`,
//         thumbnailWidth: 75,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production',
//     },
//     // 2nd row
//     {
//         src: `${imagePath}3Tacchi1.jpg`,
//         thumbnail: `${imagePath}3Tacchi1Thumb.jpg`,
//         thumbnailWidth: 61,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production',
//     },
//     {
//         src: `${imagePath}3Tacchi3.jpg`,
//         thumbnail: `${imagePath}3Tacchi3Thumb.jpg`,
//         thumbnailWidth: 63,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production',
//     },
//     {
//         src: `${imagePath}4CLOOS1.jpg`,
//         thumbnail: `${imagePath}4CLOOS1Thumb.jpg`,
//         thumbnailWidth: 65,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production',
//     },
//     {
//         src: `${imagePath}4CLOOS3.jpg`,
//         thumbnail: `${imagePath}4CLOOS3Thumb.jpg`,
//         thumbnailWidth: 60,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production',
//     },
//     // 3rd row
//     {
//         src: `${imagePath}5Thompson1.png`,
//         thumbnail: `${imagePath}5Thompson1Thumb.jpg`,
//         thumbnailWidth: 60,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production',
//     },
//     {
//         src: `${imagePath}6Assembly1.jpg`,
//         thumbnail: `${imagePath}6Assembly1Thumb.jpg`,
//         thumbnailWidth: 60,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production',
//     },
//     {
//         src: `${imagePath}6Assembly3.jpg`,
//         thumbnail: `${imagePath}6Assembly3Thumb.jpg`,
//         thumbnailWidth: 61,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production',
//     },
//     {
//         src: `${imagePath}6Assembly4.jpg`,
//         thumbnail: `${imagePath}6Assembly4Thumb.jpg`,
//         thumbnailWidth: 74,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production',
//     },
//     // 4th row
//     {
//         src: `${imagePath}6Assembly6.jpg`,
//         thumbnail: `${imagePath}6Assembly6Thumb.jpg`,
//         thumbnailWidth: 60,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production',
//     },
//
//     {
//         src: `${imagePath}6Assembly7.jpg`,
//         thumbnail: `${imagePath}6Assembly7Thumb.jpg`,
//         thumbnailWidth: 60,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production',
//     },
//     {
//         src: `${imagePath}7PaintingShop1.jpg`,
//         thumbnail: `${imagePath}7PaintingShop1Thumb.jpg`,
//         thumbnailWidth: 80,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production',
//     },
//     {
//         src: `${imagePath}7PaintingShop4.jpg`,
//         thumbnail: `${imagePath}7PaintingShop4Thumb.jpg`,
//         thumbnailWidth: 70,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production',
//     },
//     {
//         src: `${imagePath}7PaintingShop12.jpg`,
//         thumbnail: `${imagePath}7PaintingShop12Thumb.jpg`,
//         thumbnailWidth: 70,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production',
//     },
//     {
//         src: `${imagePath}8Quality11.jpg`,
//         thumbnail: `${imagePath}8Quality11thumb.jpg`,
//         thumbnailWidth: 60,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production',
//     },
//     {
//         src: `${imagePath}8Quality3.jpg`,
//         thumbnail: `${imagePath}8Quality3Thumb.jpg`,
//         thumbnailWidth: 55,
//         thumbnailHeight: thumbnailHeightMobile,
//         alt: 'Kyashif production',
//     },
// ];
